import React from 'react';
import {Grid, Modal} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {getLanguage} from "../../helper/util";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import DropdownItem from "../dropdownMenu/DropdownItem";
import {withTranslation} from "react-i18next";
import {translateHeader} from "../editHeader/utils";
import {getTranslatedText} from "../../helper/TextTranslatorUtil";
import * as utils from "../editLink/EditLinkComponent/utils";


const TranslatorModal = (props) => {
    const {
        i18n,
        isTranslatorOpen,
        setIsTranslatorOpen,
        setTargetLanguage,
        targetLanguage,
        language,
        textStore,
        activeLanguageOptions,
        id,
        editType,
        setIsEditState,
        onClose
    } = props;

    return (
        <Modal className={"admin-primary"}
               style={{minHeight: "20%"}}
               open={isTranslatorOpen}>
            <Modal.Header
                className={"admin-primary"}>{i18n.t("edit:translate.translate")}</Modal.Header>
            <Modal.Content className={"admin-primary"}>
                <Grid>
                    <Grid.Row>
                        <p className={"selected_lang"}>{i18n.t("edit:translate.translate_from")} {targetLanguage.language}</p>
                        <DropdownMenu name={"Select Language"}
                                      selector={true}
                                      defaultValue={targetLanguage.language}
                                      onChange={(selection) => {
                                          setTargetLanguage(getLanguage(selection))
                                      }}>
                            {
                                activeLanguageOptions.map((language, index) => (
                                    <DropdownItem key={index}>{language.value}</DropdownItem>
                                ))
                            }
                        </DropdownMenu>
                        <p className={"selected_lang"}>{i18n.t("edit:translate.translate_in")} {language.language}</p>
                    </Grid.Row>
                    <p className={"admin-highlight"}>{i18n.t("edit:translate.format_warning")}</p>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button className={"admin-button-primary"} onClick={() => {
                    switch (editType) {
                        case 'header':
                            translateHeader(id, language, targetLanguage, textStore).then((text) => {
                                onClose(text);
                                setIsTranslatorOpen(false);
                            });
                            break;
                        case 'html':
                            getTranslatedText(language.languageCode,
                                targetLanguage.languageCode,
                                textStore[id][targetLanguage.languageCode].content)
                                .then(translatedText => {
                                    onClose(translatedText);
                                    setIsTranslatorOpen(false);
                                    setIsEditState(false);
                                    setIsEditState(true);
                                })
                            break;
                        case 'text':
                            getTranslatedText(language.languageCode, targetLanguage.languageCode,
                                textStore[id][targetLanguage.languageCode].content)
                                .then((translatedText) => {
                                    onClose(translatedText)
                                    setIsTranslatorOpen(false);
                                })
                            break;
                        case 'link':
                            utils.translateLink(id, language, targetLanguage, textStore).then((translatedLink) => {
                                onClose(translatedLink);
                                setIsTranslatorOpen(false);
                            });
                            break;
                        default:
                            break;
                    }
                    setIsTranslatorOpen(false);
                }}>{i18n.t("edit:translate.translate")}</Button>
                <Button className={"admin-button-warn"}
                        onClick={() => {
                            setIsTranslatorOpen(false);
                        }}>{i18n.t("common:common.cancel")}</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default withTranslation(['edit', 'common'])(TranslatorModal);